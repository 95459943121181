import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
  Modal,
  Tab,
  Tabs,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Pagination,
  IconButton
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import TableC from "../../components/table";
import Header from "../../components/header/header";
import Footer from "../../components/footer";
import useStyles from "../../theme/styles";
import { CircularProgress } from "@mui/material";
import ModalCustom from "../../components/modal";
import CheckboxCustom from "../../components/ui/checkbox";
import ButtonCustom from "../../components/ui/button";
import { getProjectByUser, deleteProject, fetchData, deleteDataset, getNumberPages, downloadAoi, runDataset } from "../../utils/requests";
import { Project } from "../../models/project";
import { Dataset } from "../../models/dataset";
import "./style.scss";
import SearchInputCustom from "../../components/ui/searchInput";
import fileDownload from 'js-file-download';
import { useTranslation } from "react-i18next";
import CustomSnackbar from "../../components/snackbar";


const initialListProject: Project[] = [
  {
    name_project: "",
    id_project: 0,
    checked: false,
    creation_date: "",
    last_execution_date: "",
    area: "",
    pricing: "",
    state: "",
  },
];

const FILE_EXTENSION_TRANSLATIONS: any = {
  "shapefile": ".zip",
  "kml": ".kml",
  "geojson": ".geojson",
  "csv": ".csv",
  "dxf": ".dxf"
}

function Projects() {
  const classes = useStyles();
  const history = useHistory();
  localStorage.setItem("editName", "");
  localStorage.setItem("editAsset", "");
  localStorage.setItem("editAttribute", "");
  localStorage.setItem("openPanelLeft", "");

  const { t } = useTranslation();

  const header = [t('common.status'), t('projects.headerArea'), t('projects.headerAOI')];//, "Est. pricing ($/Km2)"];
  const headerDataset = ["", t('projects.headerCreationDate'), t('projects.headerFileName'), t('common.status'), t('projects.headerImageDate'), t('projects.inputImage')];//, "Cost"];

  const queryParameters = new URLSearchParams(window.location.search)
  const projectNameFilter = queryParameters.get("projectName")

  const [openLoader, setOpenLoader] = useState(false);
  const [openModalRun, setOpenModalRun] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDeleteDataset, setOpenModalDeleteDataset] = useState(false);

  const [activeRemoveDataset, setActiveRemoveDataset] = useState(false);
  const [activeExecuteDataset, setActiveExecuteDataset] = useState(false);

  const [listProject, setListProject] = useState(initialListProject);
  const [searched, setSearched] = useState<string>(projectNameFilter ? projectNameFilter : "");
  const [valueTab, setValueTab] = useState<string>("stats");
  const [listDataset, setListDataset] = useState([]);

  const [orderByProject, setOrderByProject] = useState("creation_date");

  const [orderByProjectList, setOrderByProjectList] = useState<any>({
    ["creation_date"]: {
      desc: true
    },
    ["last_execution_date"]: {
      desc: true,
    },
    ["name_project"]: {
      desc: true,
    }
  });

  const [listIdProjectRemove, setListIdProjectRemove] = useState<any>([]);
  const [idProjectByDatasetRemove, setIdProjectByDatasetRemove] = useState<any>();
  const [numberPages, setNumberPages] = useState(1);
  const [page, setPage] = useState(1);

  const [snackbarProps, setSnackbarProps] = useState({ message: '', severity: '' })
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const formatValue = (value: any) => {
    const parsed = parseFloat(value)
    const valueInKilometers = parsed / 10 ** 6

    if (parseFloat(valueInKilometers.toFixed(3)) === 0) {
      const formated = parsed.toFixed(3).replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return <Typography>{formated} (m<sup>2</sup>)</Typography>;
    }
    else {
      const formated = valueInKilometers.toFixed(3).replace(".", ",").replace(/\B(?=(\d{5})+(?!\d))/g, ".");
      return <Typography>{formated} (km<sup>2</sup>)</Typography>
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const getProjectList = async (orderBy: string | null = null, isDesc: boolean | null = null) => {
    try {
      const projectName = searched ? searched : null
      // Only gets orderBy and isDesc from params if project oreder changes because state may not be correctly updated
      const orderByParam = orderBy == null ? orderByProject : orderBy
      const isOrderByDesc = isDesc == null ? orderByProjectList[orderByProject].desc : isDesc

      await getNumberPages(projectName).then(function (response) {
        if (response.status === 200) {
          setNumberPages(response.data.number_of_pages);
        }
      }).catch(function (err) {
        setOpenLoader(false);
        setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
        setOpenSnackbar(true)
      });
      await getProjectByUser(projectName ? 1 : page, projectName, orderByParam, isOrderByDesc)
        .then(function (response) {
          if (response.status === 200) {
            setOpenLoader(false);
            const listProjectCheck = response.data.message.map((p: any) => {
              return (p = {
                ...p,
                checked: false,
              });
            });
            setListProject(listProjectCheck);
          } else {
            setOpenLoader(false);
            setListProject([]);
          }
        })
        .catch(function (err) {
          // some error here
          setOpenLoader(false);
          setListProject([]);
          setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
          setOpenSnackbar(true)
        });
    } catch (e) {
      // some error here
    }
    localStorage.removeItem("idProject");
    localStorage.removeItem("nameProject");
  };

  useEffect(() => {
    setOpenLoader(true);
    getProjectList();
  }, []);

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchedVal = event.target.value;
    setSearched(searchedVal);
  };

  const handleOpenExecute = async (id_project: number) => {
    //recojer los checkbox de ese proyecto que estan checked
    const datasetSelect = listDataset.filter((v: any) => v.checked && v.id_project === id_project);
    const datasetsToSend: any[] = []
    datasetSelect.forEach((d: any) => {
      const basemap = d.input_alg_parameters !== null ? d.input_alg_parameters.basemap_name : false

      const optionalParameters = {
        area: undefined,
        spatial_scale: false
      }

      if (d.name_attribute === 'Solar Panels' ||
        d.name_attribute === "Powerblocks" ||
        d.name_attribute === "Roads (Solar Plants)" ||
        d.name_attribute === "Solar Panels Plants" ||
        d.name_attribute === "Solar Trackers Plants" ||
        d.name_attribute === "Wind Turbines" ||
        d.name_attribute === "Vehicles" ||
        d.name_attribute === "Roads"
      ) {
        //send params for download image first
        optionalParameters.area = d.input_alg_parameters.mask.features[0]

      } else if (d.name_attribute === "Segmentation") {
        //send params for download image first
        optionalParameters.area = d.input_alg_parameters.mask.features[0]
        optionalParameters.spatial_scale = true
      }

      const datasetToSend = { ...d, basemap_name: basemap, ...optionalParameters }
      datasetsToSend.push(datasetToSend)

    });

    runDataset(id_project, datasetsToSend).catch(function (err) {
      setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
      setOpenSnackbar(true)
    });

    const id_user = localStorage.getItem("id_user");
    await fetchData(`${id_user}/${id_project}`, "GET_DATASET")
      .then(function (response) {
        const listDatasetCheck = response.data.message.map((d: any) => {
          return (d = {
            ...d,
            checked: false,
            display: true,
          });
        });
        setListDataset(listDatasetCheck);
        setOpenLoader(false);
      })
      .catch(function (err) {
        setListDataset([]);
        setOpenLoader(false);
        setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
        setOpenSnackbar(true)
      });

    setOpenModalRun(true);
  };

  const deleteDatasetByCheck = async () => {
    setOpenLoader(true);
    //recojer los checkbox de ese proyecto que estan checked
    const datasetSelect = listDataset.filter((v: any) => v.checked && v.id_project === idProjectByDatasetRemove);
    const arrayIdDatasetDelete: any = [];
    datasetSelect.forEach(async (d: Dataset) => {
      arrayIdDatasetDelete.push(d.id_dataset);
    });
    datasetSelect.length === 0 && setOpenLoader(false);
    const listDatasetNew: any = [];
    datasetSelect.forEach(async (d: Dataset) => {
      await deleteDataset(idProjectByDatasetRemove, d.id_dataset)
        .then(async function (response) {
          if (response.status === 200) {
            listDataset.forEach((dS: any, index: number) => {
              dS.checked = false;
              dS.display = true;
              if (!arrayIdDatasetDelete.includes(dS.id_dataset)) {
                listDatasetNew.push(dS);
              }
            });
          }
          setListDataset(listDatasetNew);
          setOpenLoader(false);
          handleChangePanel(idProjectByDatasetRemove);
        })
        .catch(function (err) {
          setListDataset([]);
          setOpenLoader(false);
          setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
          setOpenSnackbar(true)
        });
    });

    setIdProjectByDatasetRemove(null);
    setOpenModalDeleteDataset(false);

    // Check if there are still datasets on the project
    const remainingDatasets = listDatasetNew.filter((d: Dataset) => d.id_project === idProjectByDatasetRemove);
    if (remainingDatasets.length === 0) {
      // Delete the project
      await deleteProject(idProjectByDatasetRemove)
        .then(function (response) {
          if (response.status === 200) {
            // Project deleted successfully
            // Perform any additional actions or updates
            getProjectList(); // Refresh the project list
          }
        })
        .catch(function (err) {
          setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
          setOpenSnackbar(true)
        });
    }
  };

  const handleOpenDeleteModal = () => setOpenModalDelete(true);
  const handleCloseModal = () => setOpenLoader(false);

  const [expanded, setExpanded] = useState<string | false>(false);

  const [valueCheckbox, setValueCheckbox] = useState<boolean | undefined>(false);
  const [activeRemove, setActiveRemove] = useState<boolean | undefined>(false);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);

  const handleChangePanel = (panel: number) => async (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? `panel${panel}` : false);
    const id_user = localStorage.getItem("id_user");

    if (isExpanded) {
      setOpenLoader(true);
      await fetchData(`${id_user}/${panel}`, "GET_DATASET")
        .then(function (response) {
          const listDatasetCheck = response.data.message.map((d: any) => {
            return (d = {
              ...d,
              checked: false,
              display: true,
            });
          });
          setListDataset(listDatasetCheck);
          setOpenLoader(false);
          setSelectedProject(panel);
        })
        .catch(function (err) {
          setListDataset([]);
          setOpenLoader(false);
          setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
          setOpenSnackbar(true)
        });
    }
    else {
      setSelectedProject(null)
    }
  };

  const handleChangeAllCheckbox = () => {
    setValueCheckbox(!valueCheckbox);
    setActiveRemove(!valueCheckbox);

    const listProjectArray = [...listProject];
    const listArray = [...listIdProjectRemove];
    listProjectArray.forEach((p: Project, i: number) => {
      listProjectArray[i].checked = !valueCheckbox;
      listArray.push(p.id_project);
    });

    if (!valueCheckbox === false) {
      setListIdProjectRemove([]);
    } else {
      setListIdProjectRemove(listArray);
    }

    setListProject(listProjectArray);
  };

  const handleCheckRemove = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.stopPropagation();

    const listProjectArray = [...listProject];
    listProjectArray[index].checked = e.target.checked;
    setListProject(listProjectArray);

    const listArray = [...listIdProjectRemove];
    const value = parseInt(e.target.value);
    if (e.target.checked) {
      if (listArray.indexOf(value) !== -1) {
        listArray.splice(listArray.indexOf(value), 1);
        setListIdProjectRemove(listArray);
      } else {
        listArray.push(value);
        setListIdProjectRemove(listArray);
      }
    } else {
      if (listArray.indexOf(value) !== -1) {
        listArray.splice(listArray.indexOf(value), 1);
        setListIdProjectRemove(listArray);
      }
    }

    if (listArray.length > 0) {
      setActiveRemove(true);
    } else {
      setActiveRemove(false);
    }
  };

  const handleClickRemoveDataset = (id_project: number) => {
    //const idProject = parseInt(e.target.dataset.id);
    //setListIdProjectRemove([idProject]);

    setIdProjectByDatasetRemove(id_project);
    setOpenModalDeleteDataset(true);
  };

  const handleChangeCheck = (e: any, id_dataset: any, id_project: any) => {
    const listDatasetNew: any = [];
    Object.values(listDataset).forEach((dataset: any) => {
      if (dataset.id_project === id_project && dataset.id_dataset === id_dataset) {
        dataset.checked = e.target.checked;
      }
      listDatasetNew.push(dataset);
    });
    setListDataset(listDatasetNew);

    let executeDataset = false;
    let removeDataset = false;
    Object.values(listDataset).forEach((dataset: any) => {
      if (dataset.checked === true) {
        removeDataset = dataset.checked;
        if (dataset.state === "Completed") {
          executeDataset = false;
        } else executeDataset = true;
      }
    });

    setActiveRemoveDataset(removeDataset);
    setActiveExecuteDataset(executeDataset);
  };

  const deleteProjectByCheck = async () => {
    setOpenLoader(true);
    const posts = await Promise.all(
      listIdProjectRemove.map(async (projectId: number) => {
        await deleteProject(projectId)
          .then(async function (response) {
            await getProjectList();
            setListIdProjectRemove([]);
            setOpenModalDelete(false);
            setActiveRemove(false);
            setOpenLoader(false);
          })
          .catch(async function (err) {
            setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
            setOpenSnackbar(true)
            return false;
          });
      }),
    );
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderBy = (type: string) => {
    if (type === "name_project") {
      const listProjectCheck1 = [...listProject].sort((x, y) => {
        if (orderByProjectList[type].desc) {
          return x.name_project.localeCompare(y.name_project);
        } else {
          return y.name_project.localeCompare(x.name_project);
        }
      });
      setListProject(listProjectCheck1);
      setOrderByProjectList((prevState: any) => ({
        ...prevState,
        [type]: {
          desc: !orderByProjectList[type].desc
        }
      }));
    } else if (type === "creation_date" || type === "last_execution_date") {

      const listProjectCheck1 = [...listProject].sort((x: any, y: any) => {
        const xDate = new Date(
          x[type].split("-")[1] +
          "-" +
          x[type].split("-")[0] +
          "-" +
          x[type].split("-")[2],
        );
        const yDate = new Date(
          y[type].split("-")[1] +
          "-" +
          y[type].split("-")[0] +
          "-" +
          y[type].split("-")[2],
        );
        if (orderByProjectList[type].desc) {
          return xDate.getTime() - yDate.getTime();
        } else {
          return yDate.getTime() - xDate.getTime();
        }
      });
      setListProject(listProjectCheck1);
      setOrderByProjectList((prevState: any) => ({
        ...prevState,
        [type]: {
          desc: !orderByProjectList[type].desc
        }
      }));
    }
    setOrderByProject(type);
  };

  const handleOrderByDB = (type: string) => {

    const orderBy = type
    const isDesc = !orderByProjectList[type].desc

    setOrderByProjectList((prevState: any) => ({
      ...prevState,
      [orderBy]: {
        desc: isDesc
      }
    }));

    setOrderByProject(orderBy);

    setOpenLoader(true);
    getProjectList(orderBy, isDesc)

  };

  window.addEventListener('scroll', () => {
    open && setAnchorEl(null);
  });

  const handleChangePagination = async (event: React.ChangeEvent<unknown>, value: number) => {
    setOpenLoader(true);
    setPage(value);
    const projectName = projectNameFilter ? projectNameFilter : null
    await getProjectByUser(value, projectName)
      .then(function (response) {
        if (response.status === 200) {
          setOpenLoader(false);
          const listProjectCheck = response.data.message.map((p: any) => {
            return (p = {
              ...p,
              checked: false,
            });
          });

          //ordenar por last_executed
          const listProjectCheck1 = listProjectCheck.sort((x: any, y: any) => {
            const xDate = new Date(
              x.creation_date.split("-")[1] +
              "-" +
              x.creation_date.split("-")[0] +
              "-" +
              x.creation_date.split("-")[2],
            );
            const yDate = new Date(
              y.creation_date.split("-")[1] +
              "-" +
              y.creation_date.split("-")[0] +
              "-" +
              y.creation_date.split("-")[2],
            );
            return yDate.getTime() - xDate.getTime();
          });
          setListProject(listProjectCheck1);
        } else {
          setOpenLoader(false);
          setListProject([]);
        }
      })
      .catch(function (err) {
        // some error here
        setOpenLoader(false);
        setListProject([]);
        setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
        setOpenSnackbar(true)
      });
  };

  const handleSearchProject = () => {
    history.push({
      pathname: '/projects',
      search: searched ? `?projectName=${searched}` : ''
    })
    getProjectList();
  }

  const [downloadActive, setDownloadActive] = useState(false)

  const handleDownloadAoiClick = async (project: any, fileFormat: string) => {
    const id_user = localStorage.getItem("id_user");
    setDownloadActive(true);
    await downloadAoi(`${id_user}/${project.id_project}`, fileFormat)
      .then(function (response) {
        if (response.status === 200) {
          fileDownload(response.data, `${project.name_project}${FILE_EXTENSION_TRANSLATIONS[fileFormat]}`)
        }
      })
      .catch(function (err) {
        setSnackbarProps({ message: err.response?.data ? err.response.data.message: t("common.genericErrorMessage"), severity: 'error' })
        setOpenSnackbar(true)
      });
    setDownloadActive(false);
  }

  const datasetsWithParameters = () => {
    const hasParameters = listDataset.reduce((acc: any, dataset: any) => {
      return (
        acc ||
        !!dataset.input_alg_parameters?.start_date ||
        !!dataset.input_alg_parameters?.end_date ||
        !!dataset.input_alg_parameters?.cloud_percentage ||
        !!dataset.input_alg_parameters?.spatial_scale ||
        !!dataset.input_alg_parameters?.minimum_segment_size
      )
    }, false)
    return hasParameters
  }

  return (
    <div className="container">
      <Modal
        open={openLoader}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerLoader">
          <CircularProgress
            sx={{
              color: "#7024D1",
            }}
            size={70}
          />
        </div>
      </Modal>
      <ModalCustom
        open={openModalRun}
        setOpen={setOpenModalRun}
        content={
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('projects.successExecuteProject')}
            </Typography>
          </div>
        }
        hasButton={false}
      />
      <ModalCustom
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        content={
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('projects.deleteProject')}
            </Typography>
          </>
        }
        accept={deleteProjectByCheck}
        labelOK={'common.deleteButton'}
      />
      <ModalCustom
        open={openModalDeleteDataset}
        setOpen={setOpenModalDeleteDataset}
        content={
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('projects.deleteDatasetConfirmation')}
            </Typography>
          </>
        }
        accept={deleteDatasetByCheck}
        labelOK={'common.deleteButton'}
      />
      <Header valueTab={"projects"} />
      <div className="body_container">
        <Typography sx={{ width: "33%", flexShrink: 0 }}>{t('projects.projectCount', { projectCount: listProject.length, projectPlural: listProject.length === 1 ? "" : "s" })}</Typography>
        <SearchInputCustom
          icon={(
            <IconButton onClick={handleSearchProject}>
              <SearchIcon />
            </IconButton>

          )}
          handleSearch={handleSearchProject}
          value={searched}
          onChange={requestSearch} />
        <div>
          <Checkbox
            onChange={handleChangeAllCheckbox}
            checked={valueCheckbox}
            sx={{
              color: "#7024d1",
              "&.Mui-checked": {
                color: "#7024d1",
              },
            }}
          />
          <ButtonCustom
            label={t('common.deleteButton')}
            className={`${activeRemove ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
            variant="contained"
            handleClick={handleOpenDeleteModal}
            tooltip={t('projects.deleteProjectTooltip')}
            disabled={!activeRemove}
          />

          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ float: "right", color: "black", textTransform: "none" }}
          >
            {
              orderByProjectList[orderByProject].desc ?
                <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} /> :
                <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />
            }
            {t('common.orderByText', { orderCriteria: t(`common.${orderByProject}`) })}
            <ExpandMoreIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            disableScrollLock={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Tooltip title={t('common.orderByText', { orderCriteria: t(`common.creation_date`) })} placement="right-start">
              <MenuItem onClick={() => handleOrderByDB("creation_date")}>
                {t(`common.creation_date`)}
                {orderByProject === "creation_date" && (
                  <div>
                    {orderByProjectList["creation_date"].desc ? <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} /> : <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />}
                  </div>
                )}
              </MenuItem>
            </Tooltip>
            <Tooltip title={t('common.orderByText', { orderCriteria: t(`common.last_execution_date`) })} placement="right-start">
              <MenuItem onClick={() => handleOrderByDB("last_execution_date")}>
                {t(`common.last_execution_date`)}
                {orderByProject === "last_execution_date" && (
                  <div>
                    {orderByProjectList["last_execution_date"].desc ? <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} /> : <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />}
                  </div>
                )}
              </MenuItem>
            </Tooltip>
            <Tooltip title={t('common.orderByText', { orderCriteria: t(`common.name_project`) })} placement="right-start">
              <MenuItem onClick={() => handleOrderByDB("name_project")}>
                {t(`common.name_project`)}
                {orderByProject === "name_project" && (
                  <div>
                    {orderByProjectList["name_project"].desc ? <ArrowDownwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} /> : <ArrowUpwardIcon style={{ color: "rgba(0, 0, 0, 0.3)" }} />}
                  </div>
                )}
              </MenuItem>
            </Tooltip>
          </Menu>


        </div>
        {listProject.length > 0 &&
          listProject.map((project, index) => (
            <Accordion
              expanded={expanded === `panel${project.id_project}`}
              onChange={handleChangePanel(project.id_project)}
              className="accordionItem"
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${project.id_project}bh-content`}
                id={`panel${project.id_project}bh-header`}
                className="accordionTitle"
              >
                <CheckboxCustom
                  sx={{
                    color: "#7024d1",
                    "&.Mui-checked": {
                      color: "#7024d1",
                    },
                  }}
                  handleChange={(_e: any) => handleCheckRemove(_e, index)}
                  value={project.id_project}
                  checked={project.checked}
                />
                <div className="container_accordionTitle">
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>{project.name_project}</Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {t('common.creation_date')}: {project.creation_date} · {t('common.last_execution_date')}:{" "}
                    {project.last_execution_date ? project.last_execution_date : "--"}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="container_details">
                  <div>
                    <Tabs
                      onChange={handleChangeTab}
                      className="menu"
                      value={valueTab}
                      textColor="secondary"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#7024d1",
                        },
                      }}
                    >
                      {/*<Tab value="home" label="Home" />*/}
                      <Tab value="stats" label={t('projects.stats')} className="tabWidth" />
                      <Tab value="datasets" label="Datasets" className="tabWidth" />
                    </Tabs>
                    {valueTab === "stats" && (
                      <div className="containerStats">
                        <TableC
                          rows={[
                            {
                              idProject: project.id_project,
                              state: project.state,
                              area: formatValue(project.area),
                              downloadAoi: true
                            },
                          ]}
                          header={header}
                          keys={["state", "area", "downloadAoi"]}
                          downloadActive={downloadActive}
                          handleDownloadAoiClick={(fileFormat: string) => handleDownloadAoiClick(project, fileFormat)}
                          isSelected={project.id_project === selectedProject}
                        />
                      </div>
                    )}
                    {valueTab === "datasets" && (
                      <div className="containerStats">
                        <TableC
                          rows={listDataset}
                          header={headerDataset}
                          keys={["checkbox", "creation_date", "name_asset,name_attribute", "state", "image_date", "inputImage"]}
                          handleChangeCheck={handleChangeCheck}
                          downloadActive={downloadActive}
                          handleDownloadAoiClick={(fileFormat: string) => handleDownloadAoiClick(project, fileFormat)}
                          isSelected={project.id_project === selectedProject}
                          showExtraParameters={datasetsWithParameters()}
                        />
                      </div>
                    )}
                  </div>
                  <div className="container_button">
                    <ButtonCustom
                      label={t('common.deleteButton')}
                      className={`${activeRemoveDataset ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
                      variant="contained"
                      handleClick={() => handleClickRemoveDataset(project.id_project)}
                      data={project.id_project}
                      disabled={!activeRemoveDataset}
                      tooltip={t('projects.deleteDatasetTooltip')}
                    />
                    <ButtonCustom
                      label={t('common.executeButton')}
                      className={`${activeExecuteDataset ? "buttonStyleColor" : "buttonStyleColoOutline"}`}
                      variant="contained"
                      handleClick={() => handleOpenExecute(project.id_project)}
                      disabled={!activeExecuteDataset}
                      tooltip={t('projects.executeDatasetTooltip')}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            count={numberPages}
            page={page}
            onChange={handleChangePagination}
          />
        </div>
      </div>
      <CustomSnackbar
        snackbarProps={snackbarProps}
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar} />
      <Footer />
    </div>
  );
}

export default Projects;
