import axios, { ResponseType } from "axios";
import { User } from "../models/user";
import { Data } from "../models/data"

const SERVER = process.env.REACT_APP_SERVER_API_DOTGIS;

export const ROUTES: any = {
  GET_DATASET: ` ${SERVER}dataset/list_user_datasets`,
  RUN_DATASET: ` ${SERVER}dataset/run_dataset`,
  GET_KPIS_ASPECT: `${SERVER}dataset/kpis_aspect`,
  GET_KPIS_RADIATION: `${SERVER}dataset/kpis_radiation`,
  GET_KPIS_SLOPE: `${SERVER}dataset/kpis_slope`,
  GET_KPIS_ALTITUDE: `${SERVER}dataset/kpis_altitude`,
  GET_KPIS_HEIGHT: `${SERVER}dataset/kpis_height`,
  GET_KPIS_SOLAR_PANELS: `${SERVER}dataset/kpis_solar_panels`,
  GET_KPIS_VEGETATION_HEALTH: `${SERVER}dataset/kpis_vegetation_health`,
  GET_KPIS_SOLAR_PANELS_PLANTS: `${SERVER}dataset/kpis_solar_panels_plants`,
  GET_KPIS_SOLAR_PANELS_PLANTS_TRACKERS: `${SERVER}dataset/kpis_solar_panels_plants_trackers`,
  GET_KPIS_WIND_TURBINES: `${SERVER}dataset/kpis_wind_turbines`,
  GET_KPIS_POWEBLOCKS: `${SERVER}dataset/kpis_powerblocks`,
  GET_KPIS_HUMIDITY: `${SERVER}dataset/kpis_humidity`,
  GET_KPIS_WATER_BODIES: `${SERVER}dataset/kpis_water_bodies`,
  GET_KPIS_SEGMENTATION: `${SERVER}dataset/kpis_segmentation`,
  GET_KPIS_VEGETATION_HEIGHT: `${SERVER}dataset/kpis_vegetation_height`,
  GET_KPIS_MONITORING: `${SERVER}dataset/kpis_monitoring`,
  GET_KPIS_BURNED_AREAS: `${SERVER}dataset/kpis_burned_areas`,
  GET_KPIS_NIGHT_TIME_LIGHTS: `${SERVER}dataset/kpis_night_time_lights`,
  GET_KPIS_CONTOUR_LINES: `${SERVER}dataset/kpis_contour_lines`,
  GET_KPIS_VEHICLES: `${SERVER}dataset/kpis_vehicles`,
  GET_KPIS_ROADS: `${SERVER}dataset/kpis_roads`,
}
export const userLogin = async (user: User) => {

  return await axios.post(
    `${SERVER}middleware/login/`,
    {
      username: user.email,
      password: user.password,
    }
  );
};

export const fetchData = async (data: any, endpoint: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${ROUTES[endpoint]}/${data}`, HEADERS
  );
};

export const fetchKPISData = async (id_project: any, id_dataset: any, endpoint: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${ROUTES[endpoint]}/${id_project}/${id_dataset}`, HEADERS
  );
};

export const getProjectByUser = async (
  page: number,
  projectName: string | null = null,
  orderBy: string | null = null,
  isOrderByDesc: boolean | null = null,
  datasetStatus: string | null = null
) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const params = {
    name_project: projectName,
    order_by: orderBy,
    desc: isOrderByDesc,
    dataset_status: datasetStatus
  }

  return await axios.get(
    `${SERVER}project/list_projects/${page}`, { ...HEADERS, params: params }
  );
};

export const getNumberPages = async (projectName: string | null = null, datasetStatus: string | null = null) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const params = {
    name_project: projectName,
    dataset_status: datasetStatus
  }

  return await axios.get(
    `${SERVER}project/number_pages`, { ...HEADERS, params: params }
  );
}

export const createProject = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formData = new FormData();

  formData.append("name_project", data.project);

  return await axios.post(
    `${SERVER}project/create_project`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
};

export const getListAtribute = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}algorithm_attribute/algorithm_attribute`, HEADERS
  );
};

export const getListAsset = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}algorithm_asset/algorithm_asset`, HEADERS
  );
};

export const getListAlgorithm = async () => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}algorithm/algorithm`, HEADERS
  );
};

export const deleteProject = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.delete(
    `${SERVER}project/delete_project/${data}`, HEADERS
  );
};

export const deleteDataset = async (id_project: any, id_dataset: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.delete(
    `${SERVER}dataset/delete_dataset/${id_project}/${id_dataset}`, HEADERS
  );
};

export const getProjectById = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}project_algorithm/filter_project_algorithm/${data}`, HEADERS
  );
};

export const postCreateDataset = async (
  data: Data,
  idProject: string,
  nameAsset: string,
  nameAttribute: string,
  basemap_name: string,
  optionalParameters: {
    dateStart: string | undefined,
    dateEnd: string | undefined,
    cloud: string | undefined,
    minArea: string | undefined,
    spatialScale: number | undefined,
    modelType: string | undefined
  }
) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formData = new FormData();
  formData.append("id_project", idProject);

  formData.append("name_asset", nameAsset);
  formData.append("name_attribute", nameAttribute);

  const { dateStart, dateEnd, cloud, minArea, spatialScale, modelType } = optionalParameters

  const rawInputAlgParameters: any = {
    mask: data,
    resolution: spatialScale ? undefined : 1,
    start_date: dateStart ? dateStart : undefined,
    end_date: dateEnd ? dateEnd : undefined,
    cloud_percentage: cloud ? cloud : undefined,
    spatial_scale: spatialScale ? spatialScale : undefined,
    minimum_segment_size: minArea ? minArea : undefined,
    model_type: modelType ? modelType : undefined
  }

  const inputAlgParameters = Object.entries(rawInputAlgParameters).reduce((acc: any, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})

  formData.append("input_alg_parameters", JSON.stringify(inputAlgParameters))

  if (basemap_name) {
    formData.append("basemap_name", basemap_name);
  }

  return await axios.post(
    `${SERVER}dataset/create_dataset`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
};

/* export const postRunDataset = async (id_project: any, id_dataset: any, basemap_name: any, optionalParameters: { area: any, spatial_scale: any }) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const { area, spatial_scale } = optionalParameters;


  const formData = new FormData();
  formData.append("id_project", id_project);

  formData.append("id_dataset", id_dataset);
  if (basemap_name) {
    formData.append("basemap_name", basemap_name);
  }
  if (basemap_name === "DTM") {
    formData.append("zoom", "14");
    formData.append("file_name", `${id_project}_${id_dataset}`);
  }
  if (spatial_scale) {
    formData.append("zoom", "15");
    formData.append("file_name", `${id_project}_${id_dataset}.tiff`);

    formData.append("area_of_interest", `${JSON.stringify(area)}`);
  } else if (area) {
    formData.append("zoom", "19");
    formData.append("file_name", `${id_project}_${id_dataset}.tiff`);

    formData.append("area_of_interest", `${JSON.stringify(area)}`);
  }

  return await axios.post(
    `${SERVER}dataset/run_dataset`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
}; */


export const runDataset = async (id_project: any, datasets: any[]) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formattedDatasets = []

  for (const dataset of datasets) {
    const formattedDataset: any = {
      id_project: id_project,
      id_dataset: dataset.id_dataset,
      file_name: `${id_project}_${dataset.id_dataset}.tiff`
    }
    if (dataset.basemap_name) {
      formattedDataset.basemap_name = dataset.basemap_name
    }
    if (dataset.basemap_name === 'DTM') {
      formattedDataset.zoom = "14";
      formattedDataset.file_name = `${id_project}_${dataset.id_dataset}`;
    }
    if (dataset.spatial_scale) {
      formattedDataset.zoom = "15";

      formattedDataset.area_of_interest = `${JSON.stringify(dataset.area)}`;
    } else if (dataset.area) {
      formattedDataset.zoom = "19";

      formattedDataset.area_of_interest = `${JSON.stringify(dataset.area)}`;
    }
    formattedDatasets.push(formattedDataset)
  }

  const formData = new FormData();
  formData.append("id_project", id_project);

  formData.append("datasets", JSON.stringify(formattedDatasets));

  return await axios.post(
    `${SERVER}dataset/run_dataset`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }
  );
};


export const downloadDataset = async (id_project: any, id_dataset: any, typeExtension: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const responseType: ResponseType = 'blob'

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: responseType
  };

  return await axios.get(
    `${SERVER}dataset/download_dataset/${id_project}/${id_dataset}/${typeExtension}`, HEADERS
  );
}

export const postUpladFilesToConvert = async (data: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const formData = new FormData();
  formData.append("file", data);

  return await axios.post(
    `${SERVER}files/upload`,
    formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      contentType: "multipart/form-data",
    }
  }
  );
};

/* export const createXyzTiles = async (url_image: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  if (url_image.endsWith("_allBands.tif")) {
    url_image = url_image.replace("_allBands.tif", "_RGB.tif");
  }

  const formData = new FormData();
  formData.append("url_image", url_image);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*"
    }
  };

  return await axios.post(
    `${SERVER}tile_service/create_tiles`,
    formData,
    config
  );
}; */

export const getXyzFolder = async (id_project: any, id_dataset: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(
    `${SERVER}tile_service/get_tiles_dir/${id_project}/${id_dataset}`, HEADERS
  );
};


export const downloadAoi = async (data: string, fileFormat: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");

  const responseType: ResponseType = 'blob'

  const HEADERS = {
    params: { file_format: fileFormat },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: responseType
  };

  return await axios.get(
    `${SERVER}dataset/download_aoi/${data}`, HEADERS
  );
};


export const existsProject = async (projectName: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const params = projectName === null ? {} : { name_project: projectName }

  return await axios.get(
    `${SERVER}project/exist_project`, { ...HEADERS, params: params }
  );

}

export const getAoiBuffer = async (aoi: any, bufferSize: string) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  formData.append("area_of_interest", JSON.stringify(aoi));
  formData.append("size", bufferSize);

  return await axios.post(
    `${SERVER}aoi/create_buffer`,
    formData,
    HEADERS
  );

}


export const checkAoi = async (aoi: any) => {
  const token: any = localStorage.getItem("token_access_imageryst");
  const HEADERS = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formData = new FormData();

  formData.append("area_of_interest", JSON.stringify(aoi));

  return await axios.post(
    `${SERVER}aoi/check_aoi`,
    formData,
    HEADERS
  );
}